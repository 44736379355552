import React, {FunctionComponent, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import GetUseCase from "../../../../../useCase/Customer/Get/GetUseCase";
import {useAppDispatch, useAppSelector} from "../../../../store/hook";
import {ReferentielInterface} from "../../../../../domain/Referentiel/ReferentielInterface";
import MatchUrl from "../../../../../domain/Utils/MatchedUrl";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import CustomerGateway from "../../../../../gateway/Customer/CustomerGateway";
import {CustomerInterface} from "../../../../../domain/Customer/Customer";
import CustomerHeaderPage from "../block/CustomerHeaderPage";
import Spinner from "../../../component/Spinner/Spinner";
import GeneralInformationPhysicalPerson
  from "../../../component/Form/Customer/GeneralInformationPhysicalPerson/GeneralInformationPhysicalPerson";
import GeneralInformationMoralPerson
  from "../../../component/Form/Customer/GeneralInformationMoralPerson/GeneralInformationMoralPerson";
import ChoicePartner from "../../../component/Sidebar/Customer/ChoicePartner";
import UploadLegacy from "../../../component/Sidebar/Customer/Upload";
import Upload from "../../../component/Upload/Upload";

import AddCityForm from "../../../component/Sidebar/Referentiel/AddCityForm";
import DocumentList from "../../../component/Document/DocumentList";
import AddressBlock from "../../../component/Form/Customer/Address/Address";
import AddAccountAddressForm from "../../../component/Sidebar/Customer/AddAddressForm";
import BankDetailsBlock from "../../../component/Form/Customer/BankDetails/BankDetails";
import AddBankInformationForm from "../../../component/Sidebar/Customer/AddBankInformationForm";
import {setIsLoading, setOpenDatalistFilterMovement, setOpenUploadForm} from "../../../../store/component/event";
import InvestmentInformationDatalist from "../../../component/Datalist/InvestmentInformationDatalist";
import MovementDatalistFilter from "../../../component/Sidebar/Movement/MovementDatalistFilter";
import ParticularityBlock from "../../../component/Form/Customer/Particularity/Particularity";
import AddRelationForm from "../../../component/Sidebar/Customer/AddRelationForm";
import NotaryBlock from "../../../component/Form/Customer/Notary/Notary";
import AddPledge from "../../../component/Sidebar/Customer/Pledge/AddPledge";
import PledgeBlock from "../../../component/Form/Customer/Pledge/Pledge";
import BeneficialOwnerForm from "../../../component/Form/Customer/BeneficialOwner/BeneficialOwner";
import AddBeneficialOwner from "../../../component/Sidebar/Customer/AddBeneficialOwner";
import UndividedForm from "../../../component/Form/Customer/Undivided/Undivided";
import AddUndivided from "../../../component/Sidebar/Customer/AddUndivided";
import MoralLegalRepresentativeForm from "../../../component/Form/Customer/MoralLegalRepresentative/MoralLegalRepresentative";
import AddMoralLegalRepresentative from "../../../component/Sidebar/Customer/AddMoralLegalRepresentative";
import LegalRepresentativesBlock from "../../../component/Form/Customer/LegalRepresentatives/LegalRepresentatives";
import AddLegalRepresentatives from "../../../component/Sidebar/Customer/AddLegalRepresentatives";
import TransactionDatalist from "../../../component/Datalist/TransactionDatalist";
import DocumentGateway from "../../../../../gateway/Prospect/Document/DocumentGateway";
import {TError} from "../../../../../domain/Error/Error";
import ErrorPage from "../../Error/ErrorPage";
import NotePad from '../../../component/Sidebar/Customer/NotePad'
import GetNameForTitle from '../../../util/GetNameForTitle'
import {clearCurrentAddresses, reloadCustomer} from "../../../../store/component/customer";
import InvestorSheetDatalist from '../../../component/Datalist/Distribution/InvestorSheetDatalist'
import InvestorSheetDatalistFilter from '../../../component/Sidebar/Distribution/InvestorSheetDatalistFilter'

interface Props {
  page: string
  mode: "add" | "edit" | "read"
}

const CustomerLayout: FunctionComponent<Props> = ({page, mode}) => {
  const {t} = useTranslation()
  const {uuid, prospectType} = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const getCustomerUseCase = new GetUseCase(new CustomerGateway())

  const customerRules = useAppSelector((state) => state.me.me?.rules.customer.actions)
  const referential: ReferentielInterface|null = useAppSelector(({referential}) => referential.referential)
  const openUploadForm = useAppSelector((state) => state.event.openUploadForm)
  const openCustomerUploadForm = useAppSelector((state) => state.event.openCustomerUploadForm)
  const reloadCustomerValue = useAppSelector((state) => state.customer.reloadCustomer)
  const openMainNavigation = useAppSelector((state) => state.event.openMainNavigation)
  const isLoading = useAppSelector((state) => state.event.isLoading)

  const [customer, setCustomer] = useState<CustomerInterface | null>(null)
  const [error, setError] = useState<TError|null>(null)
  const [status, setStatus] = useState<string>("")

  const isLectureMode = MatchUrl.find(location.pathname, 'lecture')
  const firstUpdate = useRef(true);

  useEffect(() => {
    if(customerRules) {
      if(!customerRules?.update && !isLectureMode) navigate(`/${t('url.customer.read-general-information')}/${uuid}`)
    }

    return () => {
      dispatch(reloadCustomer())
      dispatch(clearCurrentAddresses())
    }
  }, [customerRules])

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (uuid && !openCustomerUploadForm.show) {
      refreshCustomer(uuid)
    }
  }, [openCustomerUploadForm, reloadCustomerValue]);

  useEffect(() => {
    if(uuid) {
      initialFetchCustomer(uuid)
    }
  }, [])


  const refreshCustomer = useCallback((uuid) => {
    getCustomerUseCase.execute(uuid).then((response) => {
      if (response !== null) {
        setCustomer(response)
        dispatch(setIsLoading(false))
      }
    })
  }, [openCustomerUploadForm])

  const initialFetchCustomer = (uuid: string) => {
    getCustomerUseCase.execute(uuid).then((res) => {
      if (res) {
        setCustomer(res)
        dispatch(setOpenDatalistFilterMovement({
          show: false,
          count: 1,
          filters: {
            name: '',
            keywords: '',
            investor_id: res.id,
          }
        }
        ))
      }
      dispatch(setIsLoading(false))
    }).catch((e) => {
      setError(e)
      dispatch(setIsLoading(false))
    })
  }

  const updateCustomerTab = (childCustomer: CustomerInterface) => {
    setCustomer({...childCustomer});
  }
  const handlerCustomer = (childCustomer: CustomerInterface) => {
    setCustomer({...childCustomer})
  }
  const handleChoosePage = (referential: ReferentielInterface, customer: CustomerInterface,) => {
    switch (page) {
      case 'GeneralInformation':
        if (customer && mode) {
          if (customer.customerType === 'physical') {
            if (mode === "edit" || mode === "read") {
              return <GeneralInformationPhysicalPerson customer={customer}
                                                       isLectureMode={isLectureMode}
                                                       handler={updateCustomerTab}
                                                       referential={referential}
                                                       setStatus={setStatus}
              />
            }
              break
          }
          if (customer.customerType === 'moral') {
            if (mode === "edit" || mode === "read") {
              return <GeneralInformationMoralPerson customer={customer}
                                                    isLectureMode={isLectureMode}
                                                    handler={handlerCustomer}
                                                    referential={referential}
                                                    setStatus={setStatus}
              />
            }
              break
          }
          break
        }
        break
      case 'Addresses':
        if (customer) {
          return <AddressBlock customer={customer}
                               isLectureMode={isLectureMode}
                               handler={handlerCustomer}
          />
        }
        break
      case 'BankDetails':
        if (customer) {
          return <BankDetailsBlock customer={customer}
                                   isLectureMode={isLectureMode}
                                   referential={referential}
          />
        }
        break
      case 'InvestmentInformation':
        if(customer?.id) {
          return (
            <>
              <InvestmentInformationDatalist investorId={customer.id}
                                             isLectureMode={isLectureMode}
                                             referential={referential}
              />
              <TransactionDatalist investorId={customer.id} isLectureMode={isLectureMode} />
            </>
          )
        }
        break
      case 'Particularity':
        if (customer) {
          return <ParticularityBlock customer={customer}
                                     isLectureMode={isLectureMode}
                                     referential={referential}
          />
        }
        break
      case 'LegalRepresentatives':
        if (customer && mode) {
          return <LegalRepresentativesBlock customer={customer} isLectureMode={isLectureMode}/>
        }
        break
      case 'Notary':
        if (customer) {
          return <NotaryBlock customer={customer}
                              isLectureMode={isLectureMode}
                              referential={referential}
          />
        }
        break
      case 'Pledge':
        if(customer?.id) {
          return <PledgeBlock investorId={customer.id} isLectureMode={isLectureMode}/>
        }
        break
      case 'BeneficialOwner':
        if (customer) {
          return <BeneficialOwnerForm customer={customer} isLectureMode={isLectureMode}/>
        }
        break
      case 'Undivided':
        if (customer) {
          return <UndividedForm customer={customer} isLectureMode={isLectureMode} />
        }
        break
      case 'MoralLegalRepresentative':
        if (customer) {
          return <MoralLegalRepresentativeForm customer={customer} isLectureMode={isLectureMode} />
        }
        break
      case 'Distribution':
        if (customer && customer.id) {
          return <InvestorSheetDatalist accountId={customer.id} />
        }
        break
      default:
        console.log(`Sorry, we are out of ${page}.`);
    }
  }
  const handleSidesBarDependReferential = (referential: ReferentielInterface) => {
    return (
      <>
        {page === "Particularity" && !isLectureMode && <AddRelationForm referential={referential}/>}
        {page === "Pledge" && customer && <AddPledge investorId={customer.id} referential={referential} />}
        {page === "BeneficialOwner" && customer && <AddBeneficialOwner customer={customer} referential={referential}/>}
        {page === "Undivided" && customer && <AddUndivided customer={customer} referential={referential}/>}
        {page === "MoralLegalRepresentative" && customer && <AddMoralLegalRepresentative customer={customer} referential={referential}/>}
        {page === "LegalRepresentatives" && !isLectureMode && <AddLegalRepresentatives referential={referential}/>}
        {page === "BankDetails" && !isLectureMode && <AddBankInformationForm referential={referential}/>}
        {page === 'Distribution' && customer && customer.id && <InvestorSheetDatalistFilter />}
      </>
    )
  }

  if (isLoading) {
    return (
      <div>
        <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
          <CustomerHeaderPage customer={customer || undefined}
                              isLectureMode={isLectureMode}
                              status={status}
                              setStatus={setStatus}
          />
          <section className="container--spinner"><Spinner size={150}/></section>
        </main>
      </div>

    )
  }

  if(error) return <ErrorPage code={error.code?.toString() ?? undefined} />

  return (
    <div>
      {referential && handleSidesBarDependReferential(referential)}
      {page === "GeneralInformation" && !isLectureMode && <ChoicePartner/> }
      {(page === "GeneralInformation" || page === "BankDetails" || page === "BeneficialOwner" || page === "Undivided" || page === "MoralLegalRepresentative") && customer &&
      <>
        <DocumentList />
        <UploadLegacy />
        <Upload openUploadForm={openUploadForm} gateway={new DocumentGateway(customer.id)} callback={setOpenUploadForm}/>
      </>
      }
      {page === "Addresses" && !isLectureMode && <AddAccountAddressForm typeAccount={"customer"} />}
      {(page === "GeneralInformation" || page === "Addresses" || page === "BeneficialOwner" || page === "Undivided" || page === "MoralLegalRepresentative" || page === "LegalRepresentatives") && <AddCityForm /> }
      {page === "InvestmentInformation" && <MovementDatalistFilter investorId={customer?.id} />}
      <NotePad title={t('common.sideBar.notePad.title', {text: GetNameForTitle("customer", customer)})}
               isLectureMode={isLectureMode}
      />

      {/* {page === "LegalRepresentatives" && customer && <LegalRepresentativesBlock customer={customer} isLectureMode={isLectureMode} />} */}

      <main className={`main-content ${openMainNavigation ? 'main-content--reduce' : ''}`}>
        {(mode === "edit" || mode === "read") && customer && <CustomerHeaderPage customer={customer}
                                                                                 isLectureMode={isLectureMode}
                                                                                 status={status}
                                                                                 setStatus={setStatus}
        />
        }

        {customer && referential && (mode === "edit" || mode === "read") ?
          handleChoosePage(referential, customer) : null}
      </main>
    </div>
  );
}

export default CustomerLayout;
